import React from 'react';

import { PortfolioPage } from '@kali/theme/lib/pages/portfolio/page';
import Layout from '../../components/layout';

export default function ProtfolioRoute() {
  //   document.title = 'About | Kalina & Co.';
  return (
    <Layout>
      <PortfolioPage id="travel" />
    </Layout>
  );
}
